import * as React from 'react';
import { useGlobalContext }from '../../context/GlobalContext';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import Seo from "../../components/Seo";
import Section from '../../components/Section';
import SectionTitle from '../../components/Section/SectionTitle';
import SectionStepByStep from '../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import Inbetweener from '../../components/Inbetweener';
import SingleCount from '../../components/SingleCount';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageHero,
    imageLeadToCash,
    imageEventMesh,
    imageIntegrationSuite,
    imageArchitecture,
    imageInbox,
    imageProcess,
    imageApplication
   } = data

  const imgHero = getImage(imageHero);
  const imgLeadToCash = getImage(imageLeadToCash);
  const imgEventMesh = getImage(imageEventMesh);
  const imgIntegrationSuite = getImage(imageIntegrationSuite);
  const imgArchitecture = getImage(imageArchitecture);
  //const imgInbox = getImage(imageInbox);
  const imgMail = getImage(imageProcess);
  const imgApplication = getImage(imageApplication);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={imgHero}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />

        <Section id="our-customer" bgColor='light' verticalAlign='start'>
          <Col className="col-lg-5 col-12">
            <div className="content-above mb-7">
              <SectionTitle small>
              Our customer 
              </SectionTitle>
              <p>
              The King Abdullah University of Science and Technology (KAUST) is a leading research university in Saudi Arabia. Spanning approximately 36 square kilometers, the KAUST campus serves as a self-sustained research and innovation hub, offering state-of-the-art facilities, on-campus housing, and a vibrant multicultural community with residents from over 100 nationalities.
              </p>
            </div>
          </Col>
          <Col className="col-lg-7 col-12">
              <Row className="align-items-stretch">
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Industry</p>
                    <h3>Higher education</h3>
                  </div>
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Type</p>
                    <h3>Research Institution</h3>
                  </div>
                </Col>
                <div className="w-100"></div>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Employees & Academic Community</p>
                    <h3>8734 Community Residents</h3>
                  </div>
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>IT Landscape</p>
                    <h3>SAP BTP Job Scheduling Service , Cloud Application Programming model</h3>
                  </div>
                </Col>
              </Row>
          </Col>
        </Section>
        
        <Section id="use-case" bgColor='dark'>
          <Row>
            <Col className="col-lg-5 col-12">
              <div className="content-above mb-7">
                <SectionTitle>
                Use case: <br />
                Wifi Request - Subscription Service for KAUST Residents 
                </SectionTitle>
                <div>
                  <p>Bring out an end-to-end solution for people living in KAUST campus to request access for high-speed internet for their residency.</p>
                </div>
              </div>
            </Col>
            <Col className="col-lg-7 col-12 justify-content-center d-flex">
              <GatsbyImage style={{width:"auto", margin:"auto"}} image={imgApplication} alt="icons showing a Lead to Cash flow with SAP"/>
            </Col>
          </Row>
          <Row className="row counter__area counter__area-2 align-items-stretch">
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={3} duration={1} title="months project duration" color="blue-2-color" />
              </div>
            </Col>
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={2} duration={1} title="Aarini consultants involved" color="green-4-color" />
              </div>
            </Col>
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={1} duration={1} title="Scheduled job running daily once" color="orange-color" />
              </div>
            </Col>
          </Row>
        </Section>
        
        <Section id="challenges" bgColor='light'>
          <SectionTitle align='center'>
            Challenges of the customer
          </SectionTitle>
          <Row>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
              <p className="fs-3">The reliance on manual procedures led to delays, inefficiencies, and potential errors in service activation and monitoring.</p>
              </div>
            </Col>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
              <p className="fs-3">KAUST campus residents faced inconvenience due to the absence of real-time updates on their installation and service status.</p>
              </div>
            </Col>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
              <p className="fs-3">Operation service team struggled with tracking installations, managing trial periods, and ensuring smooth transitions to paid subscriptions.</p>
              </div>
            </Col>
          </Row>
        </Section>

        <Section id="solution" bgColor='dark' verticalAlign='start'>
          <Col className="col-lg-5 col-12">
            <div className="content-above mb-7">
              <SectionTitle small>
                Solution
              </SectionTitle>
              <div>
              <p>To address the challenges faced by residents and service providers, we implemented a solution to automate the Wi-Fi installation, subscription management, and trial period monitoring process. The new system creates service requests for operation team for new Wi-Fi requests from residents automatically, ensuring timely service activation and providing updates to residents about their subscription status.</p>
              <p>Additionally, a tool for system admins to manage invoicing seamlessly. The system effectively manages trial periods, automatically transitioning users from trial to paid subscriptions, and sending timely reminder emails to residents as their trial period nears its end.</p>
                </div>
            </div>
          </Col>
          <Col className="col-lg-7 col-12">
              <Row className="align-items-stretch">
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100 wow">
                    <GatsbyImage className="mb-4" image={imgIntegrationSuite} alt="Icon representing SAP Integration Suite" />
                    <h3>SAP Job Scheduling Service</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgEventMesh} alt="Icon representing SAP Mesh Events" />
                    <h3>SAP CAP</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgEventMesh} alt="Icon representing SAP Mesh Events" />
                    <h3>SAP UI5</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100 wow">
                    <GatsbyImage className="mb-4" image={imgIntegrationSuite} alt="Icon representing SAP Integration Suite" />
                    <h3>Cloud Foundry</h3>
                  </div>
                </Col>
              </Row>
          </Col>
        </Section>

        <Section id="solution" bgColor='light'>
          <GatsbyImage image={imgArchitecture} alt="Icon representing SAP integrations" style={{width:"auto", margin:"auto"}}/>
        </Section>
        <Section id="solution" bgColor='light'>
          <GatsbyImage image={imgMail} alt="Icon representing SA" style={{width:"auto", margin:"auto"}}/>
        </Section>

        {/* 
        Inbetweener
         */}
        <Inbetweener bgColor='dark' textColor='dark'>
        Enjoy faster Wi-Fi activation, seamless trial-to-paid transitions, and real-time updates, all while simplifying invoicing for admins!" 
        </Inbetweener>

        {/* 
        SectionStepByStep
         */}
        <SectionStepByStep bgColor='white'
          title="Benefits of SAP Job Scheduling Service"
          checkItems={[
            "Effortless Wi-Fi Access for KAUST Residents.",
            "Automated Mails and Workflows Running Daily.",
            "Optimized Trial and Subscription Management.",
            "Improved Resource Utilization.",
            "Smooth Transition from Trial to Paid Service.",
          ]}
          showButton={false}
        />
         
        {/*
        SCHEDULE SESSION
        */}
        <SectionScheduleAssessment 
          bgColor='dark' 
          topics='sap-btp'
          title="Interested? Schedule an SAP BTP exploration and advisory session"
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
          buttonText='Plan an exploration and advisory session'
        />
        
        <Footer />
      </>
  );
};

export default Home;

export const query = graphql`
  query Home {
    imageHero: file(relativePath: { eq: "wifiKaustCampus.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1700
          height: 425
          quality: 100
          placeholder: BLURRED
          webpOptions: { quality: 100 }
        )
      }
    }
    imageLeadToCash: file(relativePath: { eq: "sap-diagram-lead-to-cash.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageEventMesh: file(relativePath: { eq: "sap_icon_event mesh.png" }) {
      childImageSharp {
        gatsbyImageData(
          height: 80
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageIntegrationSuite: file(relativePath: { eq: "sap_icon_integration_suite.png" }) {
        childImageSharp {
        gatsbyImageData(
          height: 80
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageArchitecture: file(relativePath: { eq: "wifi1.png" }) {
        childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 100
          placeholder: BLURRED
          webpOptions: { quality: 100 }
        )
      }
    }
    imageProcess: file(relativePath: { eq: "wifi3.png" }) {
        childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 100
          placeholder: BLURRED
          webpOptions: { quality: 100 }
        )
      }
    }
    imageApplication: file(relativePath: { eq: "wifi6.png" }) {
        childImageSharp {
        gatsbyImageData(
          width: 1000
          quality: 100
          placeholder: BLURRED
          webpOptions: { quality: 100 }
        )
      }
    }
  }`